.react-datepicker-popper {
	z-index: 1300;
}
.react-datepicker-popper[data-placement^="bottom"] {
	padding-top: 0;
}
.react-datepicker__header {
	background-color: #fff;
}
.react-datepicker__day--in-range {
	background-color: #f2c511;
}
.react-datepicker__close-icon::after {
	background-color: #f2c511;
}
