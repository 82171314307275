.MuiOutlinedInput-root.Mui-error {
     color: red;
   }
  
.MuiSvgIcon-root.Mui-active{
     color : #3475da  ;
     font-weight: 600 !important
}   
.MuiStepLabel-root.Mui-disabled{
     color : rgba(0, 0, 0, 0.38) !important
}
.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root.Mui-active{
     color : #3475da  ;
     font-weight: 700 !important
}
.MuiButtonBase-root-MuiIconButton-root{
     color: #9d465f !important;
}
.Mui-completed{
     font-weight: 700 !important
}
.MuiStepLabel-label.Mui-active{
     font-weight: 700; 
}
.MuiSvgIcon-root-MuiStepIcon-root{
 color :rgba(0, 0, 0, 0.38)   !important
}
.MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
     color : #63a771 !important 
}
.MuiStepConnector-line  {
     min-height: 4vh !important;
 }

