:root {
	--rs-border-primary: var(--rs-gray-400);
	--rs-input-focus-border: #4a469d;
	--rs-text-secondary: rgba(0, 0, 0, 0.6);
	--rs-text-primary: black;
	--rs-input-bg : #8e919127;
}
.rs-picker-default  {
     background-color: #8e919100 !important;
}
.rs-picker-toggle-read-only{
     background-color: #5c90e1 !important;
}
.rs-btn {
	border-radius: 4px !important;
	color: rgba(0, 0, 0, 0.87);
	transition: none !important;
	font-size: 1rem !important;
	line-height: 1.5rem !important;
}
.rs-btn:hover {
	border-color: black;
}
.rs-btn:focus,
.rs-picker-toggle-active,
.rs-picker-menu {
	box-shadow: none !important;
	border: 1px solid #aeaeae;
}
.rs-picker-toggle-active {
	border: 2px solid #4a469d !important;
	transition: none !important;
}
.rs-check-item:hover {
	background-color: rgba(0, 0, 0, 0.04) !important;
}
.rs-check-item-focus {
	background-color: rgba(0, 0, 0, 0.04) !important;
}
.rs-picker-menu {
	z-index: 1300;
}
.rs-picker-search-bar-input {
	box-shadow: none !important;
}
